<template>
    <!-- <div class="backg">
        <v-container fluid class="mb-0 pa-0">
            <v-row
                class="fill-height my-0 flex-column"
                align="center"
                justify="center"
            >
                <h1 class="primary--text my-10">
                    <span class="font-weight-light">Alto ahí Onii-chan! </span>
                    
                </h1>
                <h1 class="primary--text my-5">
                    <span ref="typed"></span>
                </h1>
                <v-btn
                    elevation="24"
                    class="navbar white--text my-10"
                    to="/home"
                >
                    Regresa al Inicio en {{ fancyTime }}
                </v-btn>
            </v-row>
        </v-container>
    </div> -->
    <div v-if="isTypeOk">
        <page-header :title="type" />
        <filter-form :type="mediaType" />

        <media-grid-large
            v-if="md"
            :loading="loading"
            :media="media"
        />
        <media-grid v-else :loading="loading" :limit="limit" :media="media" />

        <!-- Pagination -->
        <div class="text-center" v-if="!loading">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-container class="max-width">
                            <v-pagination
                                @input="changePage"
                                v-model="page"
                                class="my-4"
                                :length="totalPages"
                                :total-visible="5"
                                :color="this.$store.state.colors[type].block"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import MediaGridLarge from "../components/MediaGridLarge";
import MediaGrid from "../components/MediaGrid.vue";
import PageHeader from "../components/PageHeader.vue";
import FilterForm from "../components/FilterForm";
import { MangasApiService } from "@/utils/mangas-api.service";
import { DoujinsApiService } from "@/utils/doujins-api.service";
//import Typewriter from "typewriter-effect/dist/core";

export default {
    components: { MediaGrid, MediaGridLarge, PageHeader, FilterForm },
    //components: {MediaGridLarge, PageHeader, FilterForm },
    name: "MediaHome",
    props: ["type", "trending"],
    metaInfo(){
        return{
            title: "M.A.N - "+this.mediaType.toUpperCase(),
            meta: [
                {
                    name: "description",
                    content: "Encuentra mangas y doujins en español",
                },
                {
                    name: "keywords",
                    content: "mangas, doujins, español, hentai, ecchi, anime",
                },
            ],
        }
    },
    data() {
        return {
            time: 10,
            limit: 50,
            loading: true,
            error: false,
            errorMsg: null,
            media: {},
            page: +this.$route.query.page,
            totalPages: null,
            sort: this.$route.query.sort,
            mediaType: null,
            search: null,
            genre: null,
            isTypeOk: true,
            md: window.innerWidth >= 960,
        };
    },
    computed:{
        // fancyTime() {
        //     if (this.time < 10) {
        //         return `00:0${this.time}`;
        //     } else {
        //         return `00:${this.time}`;
        //     }
        // },
    },
    methods: {
        toObject(arr) {
            var rv = {};
            for (var i = 0; i < arr.length; ++i)
                rv[i] = arr[i];
            return rv;
        },
        validateType() {
            const possibleTypes = ["doujin", "manga"];
            const requestedType = this.$route.params.type;
            //console.log("MEDIA HOME")
            //console.log(requestedType)
            this.isTypeOk = possibleTypes.includes(requestedType);
            return this.isTypeOk;
        },
        // Adds ?query to url
        addQuery(queryName, queryValue) {
            this.$router.push({
                query: Object.assign({}, this.$route.query, {
                    [queryName]: queryValue,
                }),
            });
        },
        changePage() {
            //console.log("pagina actual "+this.$route.query.page)
            //console.log("pagina nueva "+this.page)
            if(this.$route.query.page !== this.page){
                if(this.page!==1){
                    this.addQuery("page", this.page);
                    this.handleFetch();
                }
                else{
                    this.$router.push({
                        query: Object.assign({}, this.$route.query, {
                            page: null,
                        }),
                    });
                    this.handleFetch();
                }
            }
        },
        // Invoke callback promise, then append response to data

        async handleResponse(options) {
            const prevPage = this.$route.params.type;
            //const res = await callback(options.vars);
            this.itemsPerPage = 12;
            if(options.vars.type==="manga"){
                this.mangasApiService = new MangasApiService();
                if(options.vars.title===undefined){//search all
                    if(options.vars.genre!==undefined){//search by genre
                        // await this.mangasApiService.findByTag(options.vars.genre).then((res)=>{
                        //     this.countManga=Number(res.data.length)
                        //     this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                        //     this.error = false;
                        //     this.media = res.data;
                        //     //console.log("BY GENRE MANGA: ALL "+res.data)
                        //     //console.log("BY GENRE MANGA COUNT: "+this.countManga+" "+options.vars.genre)
                        // })
                    }
                    else{
                        await this.mangasApiService.getAll().then((res)=>{
                        this.countManga=Number(res.data.length)
                        this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                        this.error = false;
                        this.media = res.data;
                        //console.log("GET ALL MANGA: "+this.media)
                        })
                    }
                }
                else {//search by name
                    if(options.vars.genre!==undefined){//search by genre
                        // await this.mangasApiService.findByTag(options.vars.genre).then((res)=>{
                        //     this.countManga=Number(res.data.length)
                        //     this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                        //     this.error = false;
                        //     this.media = res.data;
                        //     //console.log("BY GENRE MANGA: "+res.data)
                        //     //console.log("BY GENRE MANGA COUNT: "+this.countManga+" "+options.vars.genre)
                        // })
                    }
                    else{
                        await this.mangasApiService.findByName(options.vars.title).then((res)=>{
                        this.countManga=Number(res.data.length)
                        this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                        this.error = false;
                        this.media = res.data;
                        //console.log("BY NAME MANGA: "+this.media)
                        })
                    }
                }
                
                let arrManga={}
                for(let i=0;i<this.countManga;i+=this.itemsPerPage){
                    arrManga[i]=this.media.slice(i,i+(this.itemsPerPage))
                    //console.log(arrManga[i])
                }
                //console.log(typeof this.media)

                this.media=Object.assign({},arrManga)
                this.media=this.media[(this.page-1)*this.itemsPerPage]
                //console.log(typeof this.media)
                //console.log("MANGA: "+this.media)
            }
            else if(options.vars.type==="doujin"){
                this.doujinsApiService = new DoujinsApiService();
                if(options.vars.title===undefined){//search all
                    if(options.vars.genre!==undefined){//search by genre
                        // await this.doujinsApiService.findByTag(options.vars.genre).then((res)=>{
                        //     this.countDoujin=Number(res.data.length)
                        //     this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                        //     this.error = false;
                        //     this.media = res.data;
                        //     //console.log("BY GENRE DOUJIN: ALL "+res.data)
                        //     //console.log("BY GENRE DOUJIN COUNT: "+this.countDoujin+" "+options.vars.genre)
                        // })
                    }
                    else{
                        await this.doujinsApiService.getAll().then((res)=>{
                        this.countDoujin=Number(res.data.length)
                        this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                        this.error = false;
                        this.media = res.data;
                        //console.log("GET ALL DOUJIN: "+this.media)
                    })
                    }
                }
                else{//search by name
                    // await this.doujinsApiService.findByName(options.vars.title).then((res)=>{
                    //     this.countDoujin=Number(res.data.length)
                    //     this.totalPages = Math.ceil(Number(res.data.length) / this.itemsPerPage);
                    //     this.error = false;
                    //     this.media = res.data;
                    //     //console.log("BY NAME DOUJIN: "+this.media)
                    // })
                }
                let arrDoujin={}
                for(let i=0;i<this.countDoujin;i+=this.itemsPerPage){
                    arrDoujin[i]=this.media.slice(i,i+(this.itemsPerPage))
                    //console.log(arrDoujin[i])
                }
                //console.log(typeof this.media)

                this.media=Object.assign({},arrDoujin)
                this.media=this.media[(this.page-1)*this.itemsPerPage]
                //console.log(typeof this.media)
                //console.log("DOUJIN: "+this.media)
            }
            //this.totalPages = Math.ceil(res.data.Page.pageInfo.total / 50);
            //this.error = false;
            //this.media = res.data;
            const currentPage = this.$route.params.type;
            
            // Check if user still in the same page
            // Useful when user switched page before promise was resolved
            if (currentPage === prevPage) {
                this.loading = false;
            }
            //console.log("VARS TITLE: "+options.vars.title)
        },
        setupQueries() {
            this.search = this.$route.query.search;
            this.typeSearch = this.$route.query.typeSearch;
            this.option = this.$route.query.option;
            this.sort = this.$route.query.sort;
            this.mediaType = this.$route.params.type;
            this.genre = this.$route.query.genre;
            //console.log(this.$route.params.type)
            // console.log("SETUP QUERIES")
            // console.log(this.search)
            // console.log(this.mediaType)
            // console.log("genero: "+this.genre)
            if (!this.$route.query.page) {
                //this.addQuery("page", 1);
                this.page = 1;
            }
            else{
                this.page = Number(this.$route.query.page);
            }
        },
        handleFetch() {
            this.loading = true;
            let vars = { perPage: 50, pageNumber: this.page };
            let options = {};
            // Check if query is empty, if yes then set to first page

            //
            if (this.search) {
                vars.title = this.search;
            }
            
            vars.type = this.type;
            //vars.sortBy = this.sort;
            //console.log("HANDLE FETCH TYPE: "+this.type)
            if (this.genre) {
                vars.genre = this.genre;
            }
            options = {
                vars,
            };
            if(this.type === "manga"){
                this.handleResponse(options);
            }
            else if(this.type === "doujin"){
                this.handleResponse(options);
            }
            //this.handleResponse(getMediaPage, options);
        },
        refreshMd() {
            this.md = window.innerWidth >= 960;
        },
    },
    mounted() {
        window.addEventListener("resize", this.refreshMd);
        // window.addEventListener("resize", this.refreshMd);
        // new Typewriter(this.$refs.typed, {
        //     strings: [
        //         "Estamos",
        //         "Trabajando",
        //     ],
        //     autoStart: true,
        //     loop: true,
        //     delay: 100,
        //     deleteSpeed: 30,
        // });
        // const timer = setInterval(() => {
        //     if (this.time === 0) {
        //         clearInterval(timer);
        //         // Check if user still on 404 page
        //         this.$router.push({ path: "/home" });
        //     }
        //     this.time--;
        // }, 1000);
    },
    beforeDestroy() {
        window.removeEventListener("reize", this.refreshMd);
    },
    // Watch for url query changes. For example, useful when user switches from anime tab to manga tab
    watch: {
        "$route.query": {
            handler() {
                if (this.validateType()) {
                    this.setupQueries();
                    this.handleFetch();
                } else {
                    this.$router.replace({ path: "/404" });
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.gaaa{
    align-items: center;
}
.backg{
    //background-image: url("../assets/mantenimiento.png");
    height: 100%;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.typewriter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.insetShadow {
    box-shadow: 0 -10px 10px #181818 inset;
}
</style>
