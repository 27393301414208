import http from "./services/http-common.js";
export class MangasApiService {
  async getAll() {
    return await http.get("/mangas");
  }
  getSixLast() {
    return http.get("/mangas/last");
  }
  getById(id) {
    return http.get(`/mangas/${id}`);
  }
  findByTag(tag) {
    return http.get(`/mangas/tag/${tag}`);
  }
  async findByName(name) {
    return await http.get(`/mangas/name/${name}`);
  }
}

export default new MangasApiService();
