<template>
    <div>
        <v-container>
            <v-row>
                <v-col class="d-flex justify-center align-center my-10">
                    <h1 class="font-weight-light text-center">
                        <div v-if="loading">
                            Buscando...
                        </div>
                        <div v-else class="primary--text">
                            <span class="font-weight-bold orange--text">
                                {{ foundTotal }}
                            </span>
                            {{ foundTotal === 1 ? "Resultado" : "Resultados" }} para
                            <span
                                class="orange--text capitalize font-weight-bold"
                                >{{ query }}</span
                            >
                        </div>
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <div v-if="anyFound">
            <search-section
                v-if="existsDoujin"
                :loading="loading"
                :found="doujinCount"
                :media="doujin"
                :query="query"
                sectionType="doujin"
            />
            <search-section
                v-if="existsManga"
                :loading="loading"
                :media="manga"
                :found="mangaCount"
                :query="query"
                sectionType="manga"
            />
        </div>
        <div v-else>
            <search-not-found />
        </div>
    </div>
</template>

<script>
import SearchNotFound from "../components/SearchNotFound";
import SearchSection from "../components/SearchSection";
//import { BooksApiService } from "../utils/books-api.service";
import { MangasApiService } from "../utils/mangas-api.service";
import { DoujinsApiService } from "../utils/doujins-api.service";
export default {
    name: "Search",
    components: {
        SearchSection,
        SearchNotFound,
    },
    metaInfo(){
        return{
            title: "M.A.N - Buscar",
            meta: [
                {
                    name: "description",
                    content: "Encuentra mangas y doujins en español",
                },
                {
                    name: "keywords",
                    content: "mangas, doujins, español, hentai, ecchi, anime",
                },
            ],
        }
    },
    data() {
        return {
            query: this.$route.query.search,
            page: 1,
            loading: true,
            doujinCount: 0,
            mangaCount: 0,
            existsDoujin: true,
            existsManga: true,
            doujin: [],
            manga: [],
            foundTotal: 0,
            anyFound: true,
        };
    },
    methods: {
        async searchQuery() {
            this.refreshData();
            // console.log(this.query)
            // console.log(this.foundTotal)
            // this.booksApiService = new BooksApiService();
            // this.booksApiService.SearchByName(this.query).then((res) => {
            //     //const response=res.data;
            //     console.log(res.data)
            //     this.setupData(res);
            // });
            // let responseManga;
            // let responseDoujin;
            //let CountManga;
            //let CountDoujin;
            this.mangasApiService = new MangasApiService();

            await this.mangasApiService.findByName(this.query).then((res) => {
                this.manga=res.data.data;
                this.mangaCount=Number(res.data.data.length);
                console.log(this.manga)
                // console.log(this.mangaCount)
                if(this.mangaCount===0){
                    this.existsManga=false;
                }
                //this.setupData(res);
            });
            this.doujinsApiService = new DoujinsApiService();
            await this.doujinsApiService.findByName(this.query).then((res) => {
                this.doujin=res.data.data;
                this.doujinCount=Number(res.data.data.length);
                // console.log(this.CountDoujin)
                console.log(this.doujin)
                // console.log(this.doujinCount)
                if(this.doujinCount===0){
                    this.existsDoujin=false;
                }
                //this.setupData(res);
            });
            //console.log("hola "+responseManga.length)
            //console.log("NUMERO TOTAL D Y M: "+this.CountManga+" "+this.CountDoujin)
            this.setupData(this.doujin,this.manga, this.doujinCount, this.mangaCount);
            //const res = await getSearchPage(this.page, 6, this.query);
            //this.setupData(res);
            // console.log("hola")
            // console.log(this.foundTotal)
        },
        refreshData() {
            this.loading = true;
            this.doujinCount = 0;
            this.mangaCount = 0;
            this.existsDoujin = true;
            this.existsManga = true;
            this.doujin = {};
            this.manga = {};
            this.anyFound = true;
            this.query = this.$route.query.search;
        },
        setupData(resDoujin, resManga, countDoujin, countManga) {
            this.doujin = resDoujin;
            this.manga = resManga;
            this.loading = false;
            // console.log("Datos: ")
            // console.log(resDoujin)
            // console.log(resManga)
            this.foundTotal =
            countDoujin + countManga;



            

            //this.mangaCount = countManga;
            //this.doujinCount = countDoujin;
            //console.log("NUMERO TOTAL D Y M: "+this.CountManga+" "+this.CountDoujin)
            //console.log("NUMERO TOTAL: "+this.foundTotal)

            // this.anyFound = [
            //     this.doujin.found,
            //     this.manga.found,
            // ].some((found) => found);
        },
    },
    watch: {
        "$route.query": {
            handler() {
                this.searchQuery();
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.capitalize {
    text-transform: capitalize;
}
</style>
