var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-width-container",staticStyle:{"overflow-y":"hidden"}},[(!_vm.error)?_c('div',[(_vm.loading)?_c('div',[_c('media-loading')],1):_c('div',[_c('v-img',{staticClass:"mb-0 d-none d-md-block",attrs:{"lazy-src":// `https://via.placeholder.com/24x5/${bannerColor.slice(
                    //     1,
                    //     -1
                    // )}/fff?text=%20`
                    _vm.CoverImg,"aspect-ratio":1265 / 266,"gradient":_vm.gradient}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-4"},[_c('v-col',{staticClass:"d-flex justify-center justify-md-start",class:{
                            'cover-image-column-wrapper': false,
                        },attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"d-flex flex-column fullWidth align-center",class:{
                                'cover-image-column': true,
                            }},[_c('media-title',{attrs:{"media":_vm.media,"mobile":true}}),_c('v-img',{staticClass:"elevation-17 rounded-lg",attrs:{"width":"250","max-height":"372","src":_vm.CoverImg},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}),_c('div',{staticClass:"d-flex flex-column mt-5"},[_c('div',[_c('media-info',{attrs:{"media":_vm.media}})],1)])],1)]),_c('media-description',{attrs:{"media":_vm.media,"mediaType":_vm.type}}),_c('v-col',{staticClass:"d-flex justify-center justify-md-start",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"d-flex flex-column fullWidth align-center"},[_c('media-link',{attrs:{"media":_vm.media,"mobile":true}})],1)])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider'),_c('v-row',_vm._l((this.media.pages),function(page,index){return _c('v-col',{key:index,staticClass:"manga-page",attrs:{"cols":"6","sm":"4","md":"3","lg":"3","xl":"3"},on:{"click":function($event){return _vm.goToReader(page)}}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":page,"aspect-ratio":"0.75"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1)],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-divider')],1)],1)],1),_c('PostPage')],1)],1)]):_vm._e(),(_vm.error)?_c('error-fullscreen',{on:{"tryAgain":_vm.handleFetch}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }