<template>
    <div>
        <!-- Header -->
        <typewriter-header />
        <!-- Anime Section -->
        <!-- <trending-section sectionType="doujin" ref="animeSection" /> -->
        <!-- Manga Section -->
        <trending-section sectionType="manga" />
        <!-- Doujin Section -->
        <trending-section sectionType="doujin" />
        <!--<fluid-media-list />-->
    </div>
</template>

<script>
import TypewriterHeader from "../components/TypewriterHeader";
import TrendingSection from "../components/TrendingSection.vue";
//import FluidMediaList from "../components/FluidMediaList";
export default {
    name: "Home",
    components: { TrendingSection, TypewriterHeader },
    metaInfo(){
        return{
            title: "MandaloAsiNoma",
            meta: [
                {
                    name: "description",
                    content: "Encuentra mangas y doujins en español",
                },
                {
                    name: "keywords",
                    content: "mangas, doujins, español, hentai, ecchi, anime",
                },
            ],
        }
    },
    //components: { TrendingSection, TypewriterHeader, FluidMediaList },
};
</script>
