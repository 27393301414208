<template>
    <v-card elevation="3" class="mediaCard">
        <div class="d-flex">
            <v-img
                @click="openMedia"
                :lazy-src="media.coverImg"
                :src="media.coverImg"
                :aspect-ratio="185 / 265"
                height="265"
                width="185"
                class="align-end flex-grow-0 cursor"
            >
            </v-img>
            <div
                class="pa-0 d-flex flex-column justify-space-between flex-grow-1"
            >
                <div class="px-3 pt-3 cardBody">
                    <!-- Card header -->
                    <div class="d-flex justify-space-between">
                        <p class="mb-1 primary--text">
                            {{ title.length > 25 ? title.slice(0, 25) + "..." : title }}
                        </p>
                    </div>
                    
                    <!-- Card description -->
                    <div class="mb-1 descriptionBlock d-flex">
                        <p
                            class="secondary--text caption"
                            v-html="description"
                        ></p>
                    </div>
<!--                    &lt;!&ndash; Card author &ndash;&gt;-->
<!--                    <div class="d-flex justify-space-between">-->
<!--                        &lt;!&ndash; Chip &ndash;&gt;-->
<!--                      <v-chip-->
<!--                          small-->
<!--                          class="mr-1 mb-1 font-weight-bold"-->
<!--                          :style="{ color: genreColor(i), backgroundColor: chipColor(i) }"-->
<!--                          @mouseover="changeColor(i, true)"-->
<!--                          @mouseleave="changeColor(i, false)"-->
<!--                      >-->
<!--                        {{ media.author }}-->
<!--                      </v-chip>-->
<!--                    </div>-->

                    <!-- Card genres -->
                    <div class="genres cardgenres">
                      <media-card-genres :media="media" />
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </v-card>
</template>

<script>
import MediaCardGenres from "./MediaCardGenres";
import {BooksApiService} from "@/utils/books-api.service";

export default {
    components: {
        MediaCardGenres,
    },
    props: {
        media: {
            type: Object,
        },
    },
    data() {
        return {
            genders: [],
            ratingIcon: "",
            ratingColorText: "",
            ratingColorIcon: "",
            hoveredChipIndex: -1, // Índice del chip sobre el cual pasa el mouse
            // genreColorDoujin: index => (index === this.hoveredChipIndex ? '#552929' : '#000'), // Color del texto
            // chipColorDoujin: index => (index === this.hoveredChipIndex ? '#ffeb3b' : '#e0e0e0'), // Color del chip
            // genreColorManga: index => (index === this.hoveredChipIndex ? '#552929' : '#000'), // Color del texto
            // chipColorManga: index => (index === this.hoveredChipIndex ? '#ffeb3b' : '#e0e0e0'), // Color del chip
            genreColor(index) {
              if (this.hoveredChipIndex === index) {
                if (this.media.type === 'manga') {
                  return '#fff6f6';
                } else {
                  return '#552929';
                }
              }
            },
            chipColor(index) {
              if (this.hoveredChipIndex === index) {
                if (this.media.type === 'manga') {
                  return '#2196f3';
                } else {
                  return '#ffeb3b';
                }
              }
            }
            // isAnyParts: this.media.episodes || this.media.chapters,
        };
    },
    computed: {
        title() {
            return this.media.title || this.media.title;
        },
        // studio() {
        //     const nodes = this.media.studios.nodes;
        //     return nodes.length ? nodes[0].name : null;
        // },
        color() {
            return this.media.coverImg.color;
        },
        // REMOVE UNNECESSARY NEW LINES, <br> TAGS
        description() {
            //const description = this.media.description.replace(/\r?\n|\r/g, "");
            // const formatted = description.replace(
            //     /<br><br \/><br><br \/>/,
            //     "<br><br>"
            // );
            return this.media.description;
            //return formatted;
        },
        format() {
            const format = this.media.format;
            return this.capitalize(format);
        },
        status() {
            const status = this.media.status;
            return this.capitalize(status);
        },
        // parts() {
        //     const episodes = this.media.episodes;
        //     const chapters = this.media.chapters;
        //
        //     if (episodes) {
        //         return `${episodes} ${episodes > 1 ? "episodes" : "episode"}`;
        //     } else {
        //         return `${chapters} ${chapters > 1 ? "chapters" : "chapter"}`;
        //     }
        // },
    },
    created() {
        this.handleFetch();
    },
    methods: {
        changeColor(index, isHover) {
          this.hoveredChipIndex = isHover ? index : -1;
        },
        async handleFetch() {
            this.booksApiService = new BooksApiService();
                await this.booksApiService.SearchByName(this.media.title).then((response)=>{
                    this.genders = response.data.genders;
                    console.log(response.data)
                    //console.log("generos CARD: "+this.genders[0].id_tag);
                }).catch((err)=>{
                    this.errorMsg = err.message;
                    console.log(err);                    
                })
        },
        capitalize(string) {
            if (string === "TV") return string;
            string = string.toUpperCase();
            const capitalized = string[0] + string.slice(1).toLowerCase();
            return capitalized;
        },
        openMedia() {
            //console.log(this.media.gender);
            //console.log(this.media.id_book);
            this.$router.push({
                name: "mediafullpage",
                params: {
                    type: this.media.type,
                    id: this.media.id,
                },
            });
        },
        getRatingVars() {
            const score = this.media.visits;
            const scoreColors = this.$store.state.colors.score;
            let textColor = "";
            let iconColor = "";
            let iconValue = "";
            if (score >= scoreColors.super.score) {
                // Super ratin
                textColor = scoreColors.super.text;
                iconColor = scoreColors.super.icon;
                iconValue = scoreColors.super.iconValue;
            } else if (score >= scoreColors.good.score) {
                // Good rating
                textColor = scoreColors.good.text;
                iconColor = scoreColors.good.icon;
                iconValue = scoreColors.good.iconValue;
            } else if (score >= scoreColors.meh.score) {
                // Meh rating
                textColor = scoreColors.meh.text;
                iconColor = scoreColors.meh.icon;
                iconValue = scoreColors.meh.iconValue;
            } else if (score > scoreColors.bad.score) {
                // Bad rating
                textColor = scoreColors.bad.text;
                iconColor = scoreColors.bad.icon;
                iconValue = scoreColors.bad.iconValue;
            } else {
                // No rating
                textColor = scoreColors.none.text;
                iconColor = scoreColors.none.icon;
                iconValue = scoreColors.none.iconValue;
            }
            return { textColor, iconColor, iconValue };
        },
    },
    mounted() {
        const ratingVars = this.getRatingVars();
        this.ratingIcon = ratingVars.iconValue;
        this.ratingColorText = ratingVars.textColor;
        this.ratingColorIcon = ratingVars.iconColor;
        this.handleFetch();
    },
};
</script>

<style lang="scss" scoped>
.fullWidth {
    width: 100%;
}

.mediaCard {
    transition: 0.3s;
    &:hover {
        transform: scale(1.03);
    }

    &:active {
        transform: scale(0.95);
    }
}

.cursor {
    cursor: pointer;
}

.cardBody {
    height: auto;
}

.capitalize {
    text-transform: capitalize;
}

.title {
    word-break: break-word;
}

.genres {
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 0;
    // background-color: #fff;
}

.descriptionBlock {
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    max-height: 64px;
    overflow-y: scroll;
}
</style>
