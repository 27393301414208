<template>
    <div>
        <v-container>
            <v-row v-if="loading">
                <v-col
                    v-for="card in limit"
                    :key="card"
                    cols="4"
                    sm="4"
                    md="2"
                    :xl="lgCols"
                >
                    <v-skeleton-loader
                        type="image"
                        :height="skeletonHeigth"
                        elevation="12"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col
                    v-for="(mediaItem, index) in media"
                    :key="index"
                    cols="4"
                    sm="4"
                    md="2"
                    :xl="lgCols"
                >
                    <media-card
                        :title="getCardTitle(mediaItem)"
                        :mediaId=mediaItem.id
                        :image="getCover(mediaItem)"
                        :mediaType=getType(mediaItem)
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import MediaCard from "./MediaCard";
export default {
    components: {
        MediaCard,
    },
    props: {
        media: {
            type: [Object, Array],
        },
        loading: {
            type: Boolean,
            default: true,
        },
        limit: {
            // ! DEV PROP; REMOVE LATER
            //* Possible props: 50 (for global section ) / 6 (for trending section)
            type: Number,
            default: 50,
        },
        // Number of columns on large screens.
        lgCols: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        getCardTitle(mediaItem) {
            // console.log("titulo");
            // console.log(mediaItem.name_book);
            return mediaItem.title;
        },
        getCover(mediaItem) {
            // console.log("cover");
            // console.log(mediaItem.coverLink);
            return mediaItem.coverImg;
        },
        getType(mediaItem){
            // console.log("tipo");
            // console.log(mediaItem.gender);
            return mediaItem.type;
        },
        resizeSkeletonHeight() {
            const width = window.innerWidth;
            if (width > 1904) {
                this.skeletonHeigth = 230;
            } else if (width > 600) {
                this.skeletonHeigth = 300;
            } else {
                this.skeletonHeigth = 170;
            }
        },
    },
    data() {
        return {
            skeletonHeigth: 150,
        };
    },
    mounted() {
        this.resizeSkeletonHeight();
        window.addEventListener("resize", this.resizeSkeletonHeight);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeSkeletonHeight);
    },
};
</script>

<style lang="scss">
.v-skeleton-loader__image {
    height: 100% !important;
}
</style>
