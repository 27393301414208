<template>
    <div
        class="mb-3 text-center d-sm-none"
        :class="[mobile ? classMobile : classDefault]"
    >
        <h2 class="mb-3 text-h6 primary--text">
             Lectura: 
             <v-btn
                class="white--text"
                elevation="24"
                :color="'red'"
                :href="readLink"
                target="_blank"
                >
                    TMO
            </v-btn>
        </h2>
        <p class="text-h6 primary--text">
            Descarga: 
            <v-btn
                class="white--text"
                elevation="24"
                :color="'blue'"
                :href="downloadLink"
                target="_blank"
                >
                    MEDIAFIRE
                </v-btn>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            classMobile: {
                "mb-3": true,
                "text-center": true,
                "d-sm-none": true,
            },
            classDefault: {
                "mb-10": true,
                "text-center": true,
                "text-md-left": true,
                "d-none": true,
                "d-sm-block": true,
            },
        };
    },
    computed: {
        readLink() {
            return this.media.link_read
        },
        downloadLink() {
            return this.media.link_download
        },
    }
};
</script>

<style lang="scss">
.spoiler {
    color: #2196f3;
}

.spoiler_active {
    font-style: italic;
}

.markdown_spoiler {
    cursor: pointer;
}
</style>
