<template>
    <div class="mb-10">
        <v-container class="mb-3">
            <v-row justify="space-between">
                <v-col cols="8">
                    <h2>
                        <v-icon color="red">mdi-fire</v-icon>
                        <span class="font-weight-light primary--text">
                            Ultimos
                        </span>
                        <!--<span :class="colors.text">{{ sectionType }}</span>-->
                        <span :class="colors.text">{{ sectionType=="doujin"?"doujins":"mangas" }}</span>
                    </h2>
                </v-col>
                <v-col class="d-flex justify-end" cols="4">
                    <v-btn
                        class="white--text"
                        elevation="24"
                        :color="colors.block"
                        :to="{
                            name: 'mediahome',
                            params: { type: sectionType },
                            //query: { sort: 'TRENDING_DESC' },
                        }"
                    >
                        Ver más
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <media-grid
            :loading="loading"
            :media="mediaArray"
            :limit="6"
            :lgCols="2"
        />
    </div>
</template>

<script>
import MediaGrid from "./MediaGrid.vue";
import { DoujinsApiService } from "../utils/doujins-api.service";
import { MangasApiService } from "../utils/mangas-api.service";
export default {
    components: { MediaGrid },
    props: {
        sectionType: {
            type: String,
            default: "manga",
            required: true,
        },
    },
    computed: {
        colors() {
            return this.$store.state.colors[this.sectionType];
        },
    },
    data() {
        return {
            loading: true,
            mediaArray: [],
            error: false,
            errorMsg: null,
        };
    },
    created() {
        this.loading = true;
        //console.log("hola")
        if(this.sectionType==="doujin"){
            //console.log("doujin")
            this.doujinsApiService = new DoujinsApiService();
            this.doujinsApiService.getSixLast().then((response)=>{
                this.mediaArray = response.data;
                console.log(this.mediaArray);
                this.loading = false;
                this.error = false;
            }).catch((err)=>{
                this.error = true;
                this.errorMsg = err.message;
            })
            //something
        }else{
            if(this.sectionType==="manga"){
                //console.log("manga")
                this.mangasApiService = new MangasApiService();
                this.mangasApiService.getSixLast().then((response)=>{
                    this.mediaArray = response.data;
                    console.log(this.mediaArray);
                    this.loading = false;
                    this.error = false;
                }).catch((err)=>{
                    this.error = true;
                    this.errorMsg = err.message;
            })
                //something
            }
        }
        // getMediaPage(vars)
        //     .then((res) => {
        //         if (!res.res.ok) throw Error(res.res.status);
        //         this.mediaArray = res.data.Page.media;
        //         this.loading = false;
        //         this.error = false;
        //     })
        //     .catch((err) => {
        //         this.error = true;
        //         this.errorMsg = err.message;
        //     });
    },
};
</script>

<style lang="scss" scoped>
h2 {
    text-transform: capitalize;
}
</style>
