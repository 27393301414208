<template>
    <v-col
        md="9"
        class="d-flex justify-center justify-md-start align-center align-md-start flex-column"
    >
        <media-title :media="media" :mobile="false" />
        <p
            class="subtitle-1 description--text text-center text-md-left"
            v-html="description"
        ></p>
        <h2 class="text-h6 primary--text pb-3 pt-2">
            Artista:
            <v-chip>
                <span class="white--text">{{ artist }}</span>
            </v-chip>
        </h2>
        <h2 class="text-h6 primary--text pb-3 pt-2">
            Visitas:
            <v-chip>
                <span class="white--text">{{ visits }}</span>
            </v-chip>
        </h2>
        <media-link :media="media" :mobile="false"/>
    </v-col>
</template>

<script>
import MediaTitle from "./MediaTitle";
import MediaLink from "./MediaLink";
import {BooksApiService} from "@/utils/books-api.service";
export default {
    components: {
        MediaLink,
        MediaTitle,
    },
    props: {
        media: {
            type: Object,
            required: true,
        },
        mediaType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visits: this.media.visits,
        };
    },
    computed: {
        title() {
            return this.media.title
        },
        subtitle() {
            return this.media.title
        },
        description() {
            return this.media.description
        },
        artist(){
            if(this.media.author!=null){
                return this.media.author
            }
            else{
                return "Vacio"
            }
        },
    },
    methods: {
        aumentervisitar(){
            console.log("visitas: "+this.media.visits);
            //this.visits = this.media.views;
            this.visits++;
            const formData = {
              visits: this.visits
            }
            console.log(formData);
            this.booksApiService= new BooksApiService();
            this.booksApiService.updateBook(this.media.id, formData).then(()=>{
                //
            }).catch((err)=>{
                console.log(err);
                console.log("error al actualizar las visitas");
            })
        }
    },
    created() {
        this.aumentervisitar();
    },
};
</script>

<style lang="scss">
.spoiler {
    color: #2196f3;
}

.spoiler_active {
    font-style: italic;
}

.markdown_spoiler {
    cursor: pointer;
}
</style>
