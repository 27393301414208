<script>
import PostPage from "../components/Disqus";
export default {
  name: "Suggestions",
  components: {
    Disqus: PostPage,
  },
}

</script>

<template>
  <div>
    <!-- Sugerencias -->
    <div class="mb-0 pa-0">
      <div
          class="d-flex align-center flex-column mb-6 bg-surface-variant"
      >
        <h1 class="my-10">
          Sugerencias
        </h1>
        <p class="text-center font-weight-light text-h6">
          ¿Quieres que traduzcamos tu manga o doujin favorito?<br>
          ¿Tienes alguna sugerencia?<br>
          Pues MandaloAsiNoma te escucha.<br>
          Cada mes se escogerá una sugerencia para traducir.<br>
          Será la sugerencia que tenga más votos.<br>
          <span class="font-italic">PD: Tambien aceptamos <a href="comisiones">comisiones</a>.</span><br>
          <span class="font-italic">PD 2: Si quieren ver el progreso de las traducciones, pueden ir al <a target="_blank" href="https://trello.com/b/vikJRfdu/mandaloasinoma-scan">Trello</a> del Scan.</span>
        </p>
      </div>
    </div>
    <div class="mx-10">
      <disqus></disqus>
    </div>
  </div>
</template>

<style scoped lang="scss">
.typewriter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.insetShadow {
  box-shadow: 0 -10px 10px #181818 inset;
}
</style>