<template>
    <div style="overflow-y: hidden;" class="max-width-container">
        <!-- OK -->
        <div v-if="!error">
            <!-- LOADER -->
            <div v-if="loading">
                <media-loading />
            </div>
            <!-- MEDIA -->
            <div v-else>
                <v-img
                    class="mb-0 d-none d-md-block"
                    :lazy-src="
                        // `https://via.placeholder.com/24x5/${bannerColor.slice(
                        //     1,
                        //     -1
                        // )}/fff?text=%20`
                        CoverImg
                    "                    
                    :aspect-ratio="1265 / 266"
                    :gradient="gradient"
                ></v-img>
                
                <v-container fluid>
                    <v-row class="pa-4">
                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            :class="{
                                'cover-image-column-wrapper': false,
                            }"
                            class="d-flex justify-center justify-md-start"
                        >
                            <div
                                class="d-flex flex-column fullWidth align-center"
                                :class="{
                                    'cover-image-column': true,
                                }"
                            >
                                <!-- MEDIA TITLE ON TOP ON SMALL SCREENS -->
                                <media-title :media="media" :mobile="true" />
                                <!-- MEDIA COVER IMAGE -->
                                <v-img
                                    width="250"
                                    max-height="372"
                                    :src="CoverImg"
                                    class="elevation-17 rounded-lg"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                                <div class="d-flex flex-column mt-5">
                                    <div>
                                        <media-info :media="media" />
                                    </div>
                                </div>
                            </div>
                            
                        </v-col>
                        <media-description :media="media" :mediaType="type" />
                        <!--LINK READ AND DOWNLOAD-->
                        <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        class="d-flex justify-center justify-md-start"
                        >
                            <div
                            class="d-flex flex-column fullWidth align-center"                                
                            >
                                <media-link :media="media" :mobile="true"/>
                            </div>
                        </v-col>                        
                    </v-row>
                    <!-- Pages -->
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-row>
                            <v-col
                                v-for="(page, index) in this.media.pages"
                                :key="index"
                                cols="6"
                                sm="4"
                                md="3"
                                lg="3"
                                xl="3"
                                class="manga-page"
                                @click="goToReader(page)"
                            >
                              <v-img
                                  :src="page"
                                  aspect-ratio="0.75"
                                  class="mb-4"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                  >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                    </v-container>
                    <PostPage></PostPage>
                </v-container>
            </div>
        </div>

        <!-- ERROR -->
        <error-fullscreen v-if="error" @tryAgain="handleFetch" />
    </div>
</template>

<script>
import MediaInfo from "../components/MediaInfo";
import PostPage from "../components/Disqus";
import MediaLink from "../components/MediaLink";
import MediaTitle from "../components/MediaTitle";
import ErrorFullscreen from "../components/ErrorFullscreen";
import MediaLoading from "../components/MediaLoading";
import MediaDescription from "../components/MediaDescription";
import { DoujinsApiService } from "@/utils/doujins-api.service";
import { MangasApiService } from "@/utils/mangas-api.service";
export default {
    // metaInfo(){
    //     return {
    //         title: `M.A.N - ${this.media.book_name}`,
    //         meta: [
    //             {
    //                 name: "description",
    //                 content: this.media.description.slice(0, 100),
    //             },
    //             {
    //                 name: "keywords",
    //                 content: this.media.genres.join(", "),
    //             },
    //         ],
    //     };
    // },
    name: "MediaFullPage",
    metaInfo(){
        return{
            title: "M.A.N - "+this.media.title,
            meta: [
                {
                    name: "description",
                    content: this.media.description
                },
                {
                    name: "keywords",
                    //content: this.media.genres.join(", ")
                },
            ],
        }
    },
    components: {
        MediaInfo,
        PostPage,
        MediaLink,
        MediaDescription,
        MediaLoading,
        ErrorFullscreen,
        MediaTitle,
    },
    props: {
        id: {
            type: [String, Number],
            //type: Number,
            required: true,
        },
    },
    data() {
        return {
            visits: 0,
            loading: true,
            media: {},
            error: false,
            errorMsg: null,
            tab: null,
            type: this.$route.params.type,
            imageLarge: null,
            imageMedium: null,
            bannerImage: true,
            bannerColor: "fff",
            CoverImg: "",
        };
    },
    computed: {
        gradient() {
            const gradients = this.$store.state.colors.gradient.bannerImage;
            //return this.$vuetify.theme.dark ? gradients.dark : gradients.light;
            return gradients.dark;
        },
    },
    methods: {
        goToReader(page) {
          this.$router.push({
            name: 'reader',
            params: {
              //page el index de la pagina
              page: this.media.pages.indexOf(page),
              type: this.type,
              id: this.id
            }
          });
        },
        handleFetch() {
            this.loading = true;
            this.type = this.$route.params.type;
            //console.log("TIPO: " + this.type);
            //console.log(this.type);
            this.error = false;
            if (this.type === "doujin") {
                //console.log("doujin")
                this.doujinsApiService = new DoujinsApiService();
                this.doujinsApiService.getById(this.id).then((response)=>{
                    this.media = response.data;
                    this.CoverImg = this.media.coverImg;
                    console.log(this.media);
                    this.loading = false;
                    this.bannerImage = false;
                    this.error = false;
                }).catch((err)=>{
                    this.error = true;
                    this.errorMsg = err.message;
                    this.loading = false;
                    
                })
                // getCharacterById(this.id)
                //     .then((res) => {
                //         if (!res.res.ok) throw Error(res.res.status);
                //         this.media = res.data.Character;
                //         this.imageLarge = this.media.image.large;
                //         this.imageMedium = this.media.image.medium;
                //         this.bannerImage = false;
                //         this.loading = false;
                //     })
                //     .catch((err) => {
                //         this.error = true;
                //         this.errorMsg = err.message;
                //         this.loading = false;
                //     });
            } else {
                //console.log("manga")
                this.mangasApiService = new MangasApiService();
                this.mangasApiService.getById(this.id).then((response)=>{
                    this.media = response.data;
                    this.CoverImg = this.media.coverImg;
                    //console.log(this.media.views);
                    this.loading = false;
                    this.error = false;
                }).catch((err)=>{
                    this.error = true;
                    this.errorMsg = err.message;
                    this.loading = false;
                    
                })
                // getMediaById(this.type, this.id)
                //     .then((res) => {
                //         if (!res.res.ok) throw Error(res.res.status);
                //         this.media = res.data.Media;
                //         this.imageLarge = this.media.coverImage.extraLarge;
                //         this.imageMedium = this.media.coverImage.medium;
                //         this.bannerImage = this.media.bannerImage;
                //         this.bannerColor =
                //             this.media.coverImage.color || "#fff";
                //         this.loading = false;
                //         this.error = false;
                //     })
                //     .catch((err) => {
                //         this.error = true;
                //         this.errorMsg = err.message;
                //         this.loading = false;
                //     });
            }
        },
    },

    watch: {
        "$route.params": {
            handler() {
                this.handleFetch();
            },
            immediate: false,
        },
        errorMsg: {
            handler() {
                if (["404", "400"].includes(this.errorMsg)) {
                    this.$router.push({ path: "/404" });
                }
            },
        },
    },
    mounted() {
        this.handleFetch();
    },
    created() {
        this.handleFetch();
    },
};
</script>

<style lang="scss" scoped>
.cover-image-column {
    position: relative;
    top: calc(-372px / 100 * 50);
}

.cover-image-column-wrapper {
    margin-bottom: calc(-372px / 100 * 50);
}

.fullWidth {
    width: 100%;
}

@media screen and (max-width: 960px) {
    .cover-image-column {
        position: inherit;
    }
    .fullWidth {
        width: auto;
    }

    .cover-image-column-wrapper {
        margin-bottom: 0;
    }
}
.max-width-container {
  max-width: 1200px; /* Ajusta este valor según tus necesidades */
  margin: 0 auto; /* Centra el contenido horizontalmente */
}

.v-img {
  max-height: 250px;
  object-fit: cover;
}
.manga-page {
  flex: 1 1 calc(100% / 5 - 10px); /* 5 items per row */
  cursor: pointer;
}

.manga-page:hover {
  transform: scale(1.05);
  //animacion para q no se vea tan brusco
  transition: transform 0.2s;
}

@media (max-width: 1200px) {
  .manga-page {
    flex: 1 1 calc(100% / 4 - 10px); /* 4 items per row */
  }
}

@media (max-width: 960px) {
  .manga-page {
    flex: 1 1 calc(100% / 3 - 10px); /* 3 items per row */
  }
}

@media (max-width: 600px) {
  .manga-page {
    flex: 1 1 calc(100% / 2 - 10px); /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .manga-page {
    flex: 1 1 100%; /* 1 item per row */
  }
}
</style>
