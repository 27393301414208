import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./utils/nprogress.css";
import VueCookies from "vue-cookies";
import VueMeta from "vue-meta";
// import { createSSRApp, renderToString } from "vue/server-renderer";
// import VueDisqus from "vue-disqus";

Vue.config.productionTip = false;
Vue.use(VueCookies);
// Vue.use(VueDisqus, {
//   shortname: "your-shortname-disqus",
// });
Vue.use(VueMeta);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// export async function render() {
//   const app = createSSRApp(App);
//   const html = await renderToString(app);
//   return `<!DOCTYPE html><html>${html}</html>`;
// }
