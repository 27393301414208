<template>
    <div
        class="mb-3 text-center d-sm-none"
        :class="[mobile ? classMobile : classDefault]"
    >
        <h2 class="mb-3 text-h4 primary--text">
            {{ title }}
        </h2>

        <p class="secondary--text">
            {{ subtitle }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            classMobile: {
                "mb-3": true,
                "text-center": true,
                "d-sm-none": true,
            },
            classDefault: {
                "mb-10": true,
                "text-center": true,
                "text-md-left": true,
                "d-none": true,
                "d-sm-block": true,
            },
        };
    },
    computed: {
        type() {
            // Characters dont have "type" key so any media without "type" will be character
            return "type" in this.media
                ? this.media.type.toLowerCase()
                : "characters";
        },
        title() {
            return this.media.title
        },
        subtitle() {
            return this.media.title_jap
        },
    },
};
</script>
