<!-- src/components/Reader.vue -->
<template>
  <!-- Pages -->
  <div style="overflow-y: hidden;" class="max-width-container">
    <!-- OK -->
    <div v-if="!error">
      <!-- LOADER -->
      <div v-if="loading">
        <media-loading />
      </div>
      <!-- MEDIA -->
      <div v-else>
        <v-container>
          <v-row justify="space-between" class="mb-0">
            <v-col cols="auto">
              <v-btn @click="goBack">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <v-btn v-if="!isCascadeMode" @click="goToBackPage">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn v-if="!isCascadeMode" disabled class="mr-0">
                <span class="btn-span">{{ currentPage + 1 }} / {{ totalPages }}</span>
              </v-btn>
              <v-btn v-if="!isCascadeMode" @click="goToNextPage">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="toggleMode">
                <v-icon>{{ isCascadeMode ? 'mdi-view-sequential' : 'mdi-view-grid' }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                    v-for="(page, index) in displayPages"
                    :key="index"
                    cols="12"
                    class="manga-page"
                    @click="handleImageClick(index)"
                >
                  <v-img
                      :src="page"
                      aspect-ratio="0.75"
                      class="mb-4"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- ERROR -->
      <div v-if="error">
        <v-container>
          <v-row justify="center">
            <v-col cols="12">
              <v-alert
                  type="error"
                  dismissible
                  v-model="error"
              >
                {{ errorMsg }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import {DoujinsApiService} from "@/utils/doujins-api.service";
import {MangasApiService} from "@/utils/mangas-api.service";
import MediaLoading from "@/components/MediaLoading.vue";

export default {
  name: 'Reader',
  components: {MediaLoading},
  metaInfo(){
    return{
      title: "M.A.N - "+this.media.title,
      meta: [
        {
          name: "description",
          content: this.media.description
        },
        {
          name: "keywords",
          //content: this.media.genres.join(", ")
        },
      ],
    }
  },
  data(){
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      media: null,
      CoverImg: null,
      loading: true,
      error: false,
      errorMsg: null,
      bannerImage: false,
      doujinsApiService: null,
      mangasApiService: null,
      isCascadeMode: false,
      currentPage: this.$route.params.page,
    }
  },
  computed: {
    pages() {
      return this.media.pages;
    },
    totalPages() {
      return this.pages.length;
    },
    displayPages() {
      return this.isCascadeMode ? this.pages : [this.pages[this.currentPage]];
    }
  },
  methods: {
    goBack() {
      //regresar a la mediafullpage
      this.$router.push({ name: "mediafullpage", params: { id: this.id, type: this.type } });
    },
    goToBackPage(){
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    toggleMode() {
      this.isCascadeMode = !this.isCascadeMode;
      // eliminar el param page si esta en modo cascada y ponerle cascade
      if (this.isCascadeMode) {
        this.$router.push({
          query: { cascade: true },
          //eliminar el param page
          params: { id: this.id, type: this.type },
        });
      } else {
        this.currentPage = 0;
        this.$router.push({
          params: { id: this.id, type: this.type , page: this.currentPage},
        });
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
      }
    },
    handleImageClick(index) {
      if (!this.isCascadeMode) {
        const imageWidth = this.$refs[`page-${index}`][0].$el.clientWidth;
        const clickPosition = event.offsetX;
        if (clickPosition < imageWidth / 2) {
          // Click en el lado izquierdo
          if (this.currentPage > 0) {
            this.currentPage--;
          }
        } else {
          // Click en el lado derecho
          if (this.currentPage < this.totalPages - 1) {
            this.currentPage++;
          }
        }
      }
    },
    handleFetch() {
      this.loading = true;
      this.type = this.$route.params.type;
      //console.log("TIPO: " + this.type);
      //console.log(this.type);
      this.error = false;
      if (this.type === "doujin") {
        //console.log("doujin")
        this.doujinsApiService = new DoujinsApiService();
        this.doujinsApiService.getById(this.id).then((response)=>{
          this.media = response.data;
          console.log(this.media);
          this.loading = false;
          this.error = false;
        }).catch((err)=>{
          this.error = true;
          this.errorMsg = err.message;
          this.loading = false;

        })
      } else {
        //console.log("manga")
        this.mangasApiService = new MangasApiService();
        this.mangasApiService.getById(this.id).then((response)=>{
          this.media = response.data;
          //console.log(this.media.views);
          this.loading = false;
          this.error = false;
        }).catch((err)=>{
          this.error = true;
          this.errorMsg = err.message;
          this.loading = false;

        })
      }
      console.log(this.media.pages);
    },
  },
  watch: {
    "$route.params": {
      handler() {
        this.handleFetch();
      },
      immediate: false,
    },
    errorMsg: {
      handler() {
        if (["404", "400"].includes(this.errorMsg)) {
          this.$router.push({ path: "/404" });
        }
      },
    },
  },
  mounted() {
    // this.handleFetch();
    this.$refs[`page-0`][0].$el.scrollIntoView({ behavior: "smooth" });
  },
  created() {
    this.handleFetch();
  },
}
</script>

<style lang="scss" scoped>
.manga-page {
  cursor: pointer;
}
.btn-span {
  background-color: #333333;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
}
</style>
