import http from "./services/http-common.js";
export class DoujinsApiService {
  async getAll() {
    return await http.get("/doujins");
  }
  getSixLast() {
    return http.get("/doujins/last");
  }
  getById(id) {
    return http.get(`/doujins/${id}`);
  }
  findByTag(tag) {
    return http.get(`/doujins/tag/${tag}`);
  }
  async findByName(name) {
    return await http.get(`/doujins/name/${name}`);
  }
}
//select *from scan.book ORDER BY id_book DESC LIMIT 6
export default new DoujinsApiService();
