import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NProgress from "nprogress";
import Search from "../views/Search.vue";
import Suggestions from "../views/Suggestions.vue";
import MediaHome from "../views/MediaHome.vue";
import MediaHomeFullPage from "../views/MediaFullPage.vue";
import NotFound from "../views/NotFound.vue";
import Commissions from "@/views/Commissions.vue";
import Reader from "@/views/Reader.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    props: true,
    meta: {
      title: "Buscar",
      description: "Buscar nuestras traducciones de Doujins y Mangas",
    },
  },
  {
    path: "/sugerencias",
    name: "sugerencias",
    component: Suggestions,
    meta: {
      title: "Sugerencias",
      description: "Sugerencias de Doujins y Mangas",
    },
  },
  {
    path: "/comisiones",
    name: "comisiones",
    component: Commissions,
    meta: {
      title: "Comisiones",
      description: "Comisiones de Doujins y Mangas",
    },
  },
  // {
  //   path: "/tutoriales",
  //   name: "tutoriales",
  //   component: () => import("../views/Tutoriales.vue"),
  //   meta: {
  //     title: "Tutoriales",
  //     description: "Tutoriales de Doujins y Mangas",
  //   },
  // },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "Home",
      description: "Bienvenido a MandaloAsiNoma",
    },
  },
  {
    path: "/search/:type",
    name: "mediahome",
    component: MediaHome,
    props: true,
  },
  {
    path: "/search/:type/:id",
    name: "mediafullpage",
    component: MediaHomeFullPage,
    props: true,
  },
  {
    path: "/search/:type/:id/:page",
    name: "reader",
    component: Reader,
    props: true,
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: "*",
    name: "notfound",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
