import http from "./services/http-common.js";
export class BooksApiService {
  async SearchByName(name) {
    return await http.get(`/books/name/${name}`);
  }
  async GetTagsByBookId(id) {
    return await http.get(`/tags/${id}`);
  }
  async getGenres() {
    return await http.get(`/tags`);
  }
  async getGenresByType(type, name) {
    return await http.get(`/tags/${type}/${name}`);
  }
  updateBook(id, data) {
    return http.patch(`/books/${id}`, data);
  }
}
//select *from scan.book ORDER BY id_book DESC LIMIT 6
export default new BooksApiService();
