<template>
    <section class='comments' aria-labelledby="comment">
    <h2 id="comment">Comentarios</h2>
    <Disqus shortname='https-mandaloasinoma-joyel-me' />
  </section>
</template>

<script>
    import { Disqus } from 'vue-disqus'

    export default {
    name: 'PostPage',
    components: {
        Disqus
    }
    // ...
    }
</script>

<style></style>