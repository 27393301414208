<template>
    <div class="d-flex flex-column mt-2">
        <center>
            <!-- <p class="text--disabled capitalize mb-1">
                {{ type }}, {{ media.startDate.year }}
            </p> -->
            <genre-chips  :genres="genders" />
        </center>
    </div>
</template>

<script>
import GenreChips from "./GenreChips";
export default {
    components: {
        GenreChips,
    },
    data() {
        return {
            genders: [],
        };
    },
    props: {
        media: {
            type: Object,
            required: true,
        },
    },
    computed: {
        type() {
            return this.media.type;
        }
    },
    methods: {
         async handleFetch() {
            this.genders=this.media.genders;
        },
    },
    // mounted() {
    //     window.addEventListener("resize", this.refreshMd);
    // },
    // beforeDestroy() {
    //     window.removeEventListener("reize", this.refreshMd);
    // },
    created() {
        this.handleFetch();
    },
};
</script>

<style lang="scss" scoped>
.capitalize {
    text-transform: capitalize;
}
</style>
