<template>
    <v-form @submit.prevent="">
        <v-container>
            <v-row class="mb-5">
                <v-col cols="12" md="5">
                    <v-text-field
                        flat
                        color="secondary--text"
                        :class="{ 'elevation-4': md }"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        maxlength="20"
                        ref="textField"
                        clearable
                        v-model="search"
                        solo
                        autocomplete="off"
                        label="Buscar..."
                        @change="applyFilter"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                    <v-select
                        hide-details
                        flat
                        :class="{ 'elevation-4': md }"
                        prepend-inner-icon="mdi-tune"
                        solo
                        :items="types"
                        label="Tipo"
                        v-model="selectedTypeSearch"
                        @change="applyFilter"
                    >
                    </v-select>
                </v-col>
                <v-col cols="4" md="2">
                    <v-select
                        hide-details
                        flat
                        :class="{ 'elevation-4': md }"
                        prepend-inner-icon="mdi-sort"
                        solo
                        :items="options"
                        label="Opciones"
                        v-model="selectedOption"
                        @change="applyFilter"
                    >
                    </v-select>
                </v-col>
                <v-col cols="4" md="2">
                    <v-select
                        hide-details
                        flat
                        :class="{ 'elevation-4': md }"
                        prepend-inner-icon="mdi-sort"
                        solo
                        :items="sorts"
                        label="Ordenar"
                        v-model="selectedSort"
                        @change="applyFilter"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
//import {BooksApiService} from "../utils/books-api.service";
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedTypeSearch: undefined,
            selectedOption: undefined,
            selectedSort: undefined,
            typeSearch: this.$route.query.typeSearch,
            search: this.$route.query.search,
            option: this.$route.query.option,
            sort: this.$route.query.sort,
            options: [
                "Fecha",
                "Alfabético",
                "Vistas",
            ],
            optionsQuery: {
                Fecha: "DATE",
                Alfabético: "ALPHABETIC",
                Vistas: "VIEWS",
            },
            types: [
                "Artista",
                "Nombre",
                "Genero"
            ],
            typesQuery: {
                Artista: "ARTIST",
                Nombre: "NAME",
                Genero: "GENRE"
            },
            genres: [
                "Any"
            ],
            sorts: ["Ascendente", "Desendente"],
            sortsQueries: {
                Ascendente: "ACSCENDING",
                Desendente: "DESCENDING",
            },
            md: window.innerWidth >= 960,
        };
    },
    
    methods: {
        async handleFetch(){
            // if(this.tipo!=this.type){
            //     this.genres = [
            //         "Any"
            //     ];
            // }
            // if(this.tipo!=this.search){
            //     this.genres = [
            //         "Any"
            //     ];
            // }
            //console.log("TIPO: "+this.type)
            //console.log("textField: "+this.search)
            // this.booksApiService= new BooksApiService();
            // await this.booksApiService.getGenresByType(this.type, this.search==""?"null":this.search).then((res) => {
            //     //console.log("generos: "+res.data)
            //     res.data.forEach(i => {
            //         //console.log(i.id_tag)
            //         this.genres.push(i.id_tag)
            //     });
            //     //this.genres = this.genres.concat(res.data);
            // });
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find((key) => object[key] === value);
        },
        filtersPreset() {
            // Grab queries from url and apply them to input fields values
            this.search = this.$route.query.search || "";
            this.typeSearch = this.$route.query.typeSearch || "";
            this.option = this.$route.query.option || "";
            //this.sort = this.$route.query.sort || "";
            this.selectedTypeSearch = 
                this.getKeyByValue(this.typesQuery, this.$route.query.typeSearch) ||
                undefined;
            this.selectedSort =
                this.getKeyByValue(this.sortsQueries, this.$route.query.sort) ||
                undefined;
            this.selectedOption =
                this.getKeyByValue(this.optionsQuery, this.$route.query.option) ||
                undefined;
        },
        applyFilter() {
            let filters = {};

            filters = {
                    typeSearch: this.typesQuery[this.selectedTypeSearch],
                    option: this.optionsQuery[this.selectedOption],
                    // genre:
                    //     this.selectedGenre === "Any" ? "" : this.selectedGenre,
                    sort: this.sortsQueries[this.selectedSort],
                    search: this.search,
                };

            // HIDE SCREEN KEYBOARD AFTER FILTER APPLIED
            const input = this.$refs.textField.$el.children[0].children[0]
                .children[0].children[0];
            input.blur();

            this.$router
                .replace({
                    query: Object.assign({}, this.$route.query, filters),
                })
                .catch((e) => e);
        },
        setMd() {
            this.md = window.innerWidth >= 960;
        },
    },
    created() {
        this.handleFetch();
    },
    mounted() {
        window.addEventListener("resize", this.setMd);
        this.handleFetch();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setMd);
        this.handleFetch();
    },
    watch: {
        "$route.params.type": {
            handler() {
                this.search = "";
                this.selectedTypeSearch = undefined;
                this.selectedOption = undefined;
                this.selectedSort = undefined;
                this.handleFetch();
                this.filtersPreset();
                
            },
            immediate: true,
        },
        "$route.query": {
            handler() {
                this.handleFetch();
                this.filtersPreset();
                
            },
        },
    },
};
</script>
