<template>
    <v-container fluid class="mb-0 pa-0">
        <v-row
            class="fill-height my-10 flex-column"
            align="center"
            justify="center"
        >
            <h1 class="primary--text">
                MandaloAsiNoma
            </h1>
            <h1 class="primary--text my-3">
                <span class="font-weight-light">Encuentra </span>
                <span ref="typed"></span>
            </h1>
            <div>
                <v-btn
                    class="ma-3"
                    v-for="(button, i) in buttons"
                    :key="i"
                    color="navbar white--text"
                    elevation="5"
                    :to="{ path: button.route }"
                >
                    <span v-if="md" :class="button.textColor">{{ button.title }}</span>
                    <v-icon :right="md" :color=button.color>
                        {{ button.icon }}
                    </v-icon>
                </v-btn>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import Typewriter from "typewriter-effect/dist/core";
export default {
    data() {
        return {
            md: window.innerWidth >= 960,
            colors: this.$store.state.colors,
            buttons: [
                {
                    title: "Mangas",
                    icon: this.$store.state.icons.manga,
                    route: "/search/manga",
                    color: "blue",
                    textColor: "blue--text"
                },
                {
                    title: "Doujins",
                    icon: this.$store.state.icons.doujin,
                    route: "/search/doujin",
                    color: "yellow",
                    textColor: "yellow--text"
                },
            ],
        };
    },
    methods: {
        setMd() {
            this.md = window.innerWidth >= 960;
        },
    },
    mounted() {
        window.addEventListener("resize", this.setMd);

        new Typewriter(this.$refs.typed, {
            strings: [
                `<span class='${this.colors.doujin.text}'>Doujins</span>`,
                `<span class='${this.colors.manga.text}'>Mangas</span>`,
            ],
            autoStart: true,
            loop: true,
            delay: 100,
            deleteSpeed: 30,
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setMd);
    },
};
</script>

<style lang="scss" scoped>
.typewriter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.insetShadow {
    box-shadow: 0 -10px 10px #181818 inset;
}
</style>
