<script>
export default {
  name: "Commissions",
}

</script>

<template>
  <div>
    <!-- Sugerencias -->
    <div class="mb-0 pa-0">
      <div
          class="mx-10 d-flex align-center flex-column mb-6 bg-surface-variant"
      >
        <h1 class="my-10">
          Comisiones
        </h1>
        <p class="text-center font-weight-light" style="font-size: 18px">
          ¿Quieres que traduzcamos tu manga o doujin favorito?<br>
          Pero no quieres esperar a que sea escogido por votación<br>
          Pues MandaloAsiNoma te escucha.<br>
          Por eso hemos abiertos las comisiones.<br>
          Puedes pagar una comision para que tu manga o doujin sea traducida.<br>
          <span class="font-italic">PD: Si te parece caro, tambien aceptamos <a href="sugerencias">sugerencias</a>.</span><br>
        </p>
        <h2 class="my-10 text-h5 font-weight-bold">Términos de Servicio</h2>
        <p class="text-start font-weight-light" style="font-size: 18px">
          <ul>
            <li>Tenemos el derecho a rechazar comisiones por cualquier motivo que consideremos oportuno.</li>
            <li>Puedes subir nuestros proyectos a otros sitios mientras se le dé el crédito adecuado.</li>
            <li>No saques la hoja de creditos.</li>
            <li>Si no deseas que subamos públicamente tu comision en cualquiera de nuestros sitios, infórmanos cuando realices tu pedido.</li>
            <li>Los reembolsos y cancelaciones de comisiones solo están permitidos si aún no se ha terminado la traducción.</li>
            <li>Proyectos pasados o Tanks incompletos están disponibles a pedido.</li>
            <li>Los precios están sujetos a cambios.</li>
          </ul>
        </p>
        <h2 class="my-10 text-h5 font-weight-bold">Proceso de Comisión</h2>
        <p class="text-start font-weight-light" style="font-size: 18px">
          Todas las comisiones se realizan actualmente a través de correo electrónico
          (<span class="font-italic">geolrco124@hotmail.com</span>) o mediante nuestra <a target="_blank" class="blue--text" href="https://facebook.com/MandaloAsiNoma">pagina de FB</a>, asegúrate de estar 100% seguro de que deseas que este proyecto sea traducido.
          Por favor envíenos un correo o mensaje a la pagina de FB con la siguiente información:
        </p>
        <p class="text-start font-weight-light" style="font-size: 18px">
          <ul>
            <li>El nombre del proyecto y su enlace; si es un Doujin/Manga, puedes enviarnos el enlace de E-H o nHentai.</li>
            <li>Especifique si la traducción es del Japonés o del Inglés.</li>
            <li>Si es un Doujin, díganos cuántas páginas y qué tan pesado es el texto dentro de ella. Si no está seguro, háganoslo saber antes de realizar cualquier pago para que podamos darle el precio.</li>
          </ul>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.typewriter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.insetShadow {
  box-shadow: 0 -10px 10px #181818 inset;
}
</style>