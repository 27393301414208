<template>
  <div fluid>
    <div>
      <v-chip
          small
          class="mr-1 mb-1 font-weight-bold mediaGenre"
          v-for="(genre, i) in genders"
          :key="i"
          :to="{
                    name: 'mediahome',
                    params: { type: $route.params.type },
                    query: { genre: genre },
                }"
          :style="{ color: genreColor(i), backgroundColor: chipColor(i) }"
          @mouseover="changeColor(i, true)"
          @mouseleave="changeColor(i, false)"
      >
        {{ genre }}
      </v-chip>
    </div>
  </div>
</template>

<script>

export default {
    props: {
        media: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
          genders: [],
          hoveredChipIndex: -1, // Índice del chip sobre el cual pasa el mouse
          // genreColorDoujin: index => (index === this.hoveredChipIndex ? '#552929' : '#000'), // Color del texto
          // chipColorDoujin: index => (index === this.hoveredChipIndex ? '#ffeb3b' : '#e0e0e0'), // Color del chip
          // genreColorManga: index => (index === this.hoveredChipIndex ? '#552929' : '#000'), // Color del texto
          // chipColorManga: index => (index === this.hoveredChipIndex ? '#ffeb3b' : '#e0e0e0'), // Color del chip
          genreColor(index) {
            if (this.hoveredChipIndex === index) {
              if (this.media.type === 'manga') {
                return '#fff6f6';
              } else {
                return '#552929';
              }
            }
          },
          chipColor(index) {
            if (this.hoveredChipIndex === index) {
              if (this.media.type === 'manga') {
                return '#2196f3';
              } else {
                return '#ffeb3b';
              }
            }
          }
        };
    },
    methods: {
        changeColor(index, isHover) {
          this.hoveredChipIndex = isHover ? index : -1;
        },
        async handleFetch() {
            this.genders = this.media.genders;
        },
        trimGenres() {
            //const max = window.innerWidth >= 960 ? 4 : 2;
            this.genres = this.media;
        },
    },
    mounted() {
        this.trimGenres();
        //("gener:" +this.genres);
        window.addEventListener("resize", this.trimGenres);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.trimGenres);
    },
    created() {
        this.handleFetch();
    },
};
</script>

<style lang="scss" scoped>
.mediaGenre {
    transition: 0.3s;
    &:hover {
        transform: scale(1.05);
    }
}
</style>
